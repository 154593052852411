*{
		margin:0;
		padding: 0;
		font-family: 'Montserrat', sans-serif;
	}
body{
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
 	background-attachment: fixed;
	background-size: cover;
	position: absolute;
}
/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
.navbar {
    transition: all 0.4s;
}

.navbar .nav-link {
	font-size: 14px;
	font-weight:bold;
}
.nav-item{
	margin: 20px;
}

.navbar .nav-link:hover,
.navbar .nav-link:focus {
    text-decoration: none;
}

.navbar .navbar-brand {
    color: #fff;
}


/* Change navbar styling on scroll */
.navbar.active {
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar.active .nav-link {
    color: #555;
}

.navbar.active .nav-link:hover,
.navbar.active .nav-link:focus {
    color: #555;
    text-decoration: none;
}

.navbar.active .navbar-brand {
    color: #555;
}


/* Change navbar styling on small viewports */
@media (max-width: 991.98px) {
    .navbar {
        background: #fff;
    }

    .navbar .navbar-brand, .navbar .nav-link {
        color: #555;
    }
}



/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/
.text-small {
    font-size: 0.9rem !important;
}

.main{
	width: 100%;
	min-height: 320px;
	text-align: center;
	position: relative;
	cursor: pointer;
	top: 30%;
	box-shadow:  2px 8px 5px rgba(0, 0, 0, 0.4);
	border-radius: 30px;
	color: #fff;
	padding: 20px;
	background-color: #1d3586;
	margin:30px;
	background-image: linear-gradient(to bottom right, #4163c7, #4e2cdd);
}




.service{
	
	
	border-radius: 10px; 
	position: absolute;
	bottom: 0px;
}

.button {
  background-color: black; 
  border: none;
  color: blue;
  height: 30px;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
 
  cursor: pointer;
  border-radius: 10px;
}

.button2{
	background-color: black; 
  border: none;
  color: blue;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
 
  cursor: pointer;
  border-radius: 10px;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    /* z-index: 999999999; */
    background: #fff;
    border: 1px solid #ccc;
	height: 75px;
}