*{
		margin:0;
		padding:0;
	}
	.hero{
		width: 100%;
		height: 100%;
		background-image: url(banner.png);
		background-position: center;
		background-size: cover;
		position: absolute; 

	}
	.login100-form{
		padding: 10px;
		margin-top: 5%;
	}
	.login100-form h4{
		font-size: 3rem;
		font-family: 'Montserrat', sans-serif;
		color: black;
		font-weight: bold;
		text-shadow: 2px 2px black;
	}
	.input100{
		width: 100%;
		padding: 20px;
		height: 50px;
		background-color: transparent;
		color: black;
		border-radius: 20px;
		margin:20px; 
		border: 2px solid black; 

	}

input {
 outline:none;
}

::placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: black;
}
.login100-form-btn{
	background-color: transparent;
	width: 30%;
	height: 50px;
	border-radius: 20px;
	border: 2px solid black;
	color:  black;
	font-weight: bold;

}
.logo img{
	
	width: 30%;
	 display: block;
  margin-left: auto;
  margin-right: auto;
  padding:20px; 

}
button:focus {outline:0;}
.col-sm-12 h3{
	text-align: center;
	font-size: 3rem;
		font-family: 'Montserrat', sans-serif;
		color: black;
		font-weight: bold;
		text-shadow: 2px 2px black;
}
.col-sm-12{
	padding: 0px;
	margin-top: 20%;

}

