*{
		margin:0;
		padding:0;
	}
	.hero{
		width: 100%;
		height: 100%;
		background-color: url(studiobg.png);
		background-position: center;
		background-size: cover;
		position: absolute; 

	}
	
	.login100{
		width:100%;
		padding: 10px;
		margin-top: auto;
	}
	.login100 h4{
		font-size: 1.5rem;
		font-family: 'Montserrat', sans-serif;
		font-weight: bold;
		text-shadow: 2px 2px black;
	}
	
	.login100-form{
		padding: 10px;
		margin-top: auto;
	}
	.login100-form h4{
		font-size: 2rem;
		font-family: 'Montserrat', sans-serif;
		font-weight: bold;
		text-shadow: 2px 2px black;
	}
	.input100{
		width: 96%;
		padding: 20px;
		height: 50px;
		background-color: transparent;
		border-radius: 20px;
		margin:20px; 
		border: 2px solid black; 

	}
.scheduler{
	margin-top:100px;
}
input {
 outline:none;
}

::placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: black;
}
.login100-form-btn{
	background-color: transparent;
	width: 30%;
	height: 40px;
	border-radius: 20px;
	border: 2px solid black;
	font-weight: bold;

}

.batch100-form-btn{
	width: 96%;
	padding: 20px;
	height: 40px;
	background-color: transparent;
	border-radius: 20px;
	margin:20px; 
	border: 2px solid black;
	text-align: left;


}
.logo img{
	
	width: 30%;
	 display: block;
  margin-left: auto;
  margin-right: auto;
  padding:20px; 

}
.modal-dialogs{
	border: 2px solid #ccc;
    padding: 0 29px 0 5px;
    z-index: 99999999;
    position: absolute;
    left: 458px;
    background: #fff;
    width: 51%;
	}
button:focus {outline:0;}
.col-sm-12 h3{
	text-align: center;
	font-size: 3rem;
		font-family: 'Montserrat', sans-serif;
		font-weight: bold;
		text-shadow: 2px 2px black;
}
.col-sm-12{
	padding: 0px;
	margin-top: 0%;

}
.dropdown-menu {
   max-height:400px;
   overflow:scroll; 
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .6em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
  
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    /* z-index: 999999999; */
    background: #fff;
    border: 1px solid #ccc;
	height: 75px;
}
.create-btn{
	background-color: #007bff;
	border: none;
	color: #fff;
}